$(document).ready(function() {

    //Menü-Icon animation auslösen
    $('.menu-icon').click(function () {
        $(this).toggleClass('open');
        $('.nav0-1').slideToggle('fast');
    });

    if(window.matchMedia('(max-width: 767px)').matches) {

        // Prüfen ob Untermenüpunkte existieren
        var submenuitem = $('nav ul li');
        submenuitem.find('div').prev('a').after('<span class="dropdown-icon visible-xs"><img alt="Dropdown-Icon" src="img/angle-down.svg"></span>');

        // Submenüebenen (de)aktivieren
        $('nav ul li span.dropdown-icon').click(function () {
            submenuitem.find('div.active').prev('span.dropdown-icon').removeClass('rotate');
            var sub = $(this).next('div');
            if (sub) {
                $(sub).toggleClass('active');
            }
            submenuitem.find('div.active').prev('span.dropdown-icon').addClass('rotate');
        });
    }

    else if(window.matchMedia('(min-width: 992px)').matches){

        // Prüfen ob Untermenüpunkte existieren
        var submenuitem = $('nav ul li');
        submenuitem.find('div').prev('a').append('<span class="dropdown-icon visible-xs"><img alt="Dropdown-Icon" src="img/angle-down.svg"></span>');

        // Submenüebenen (de)aktivieren
        $('nav ul li a').hoverIntent(function () {
            submenuitem.find('div.active').prev('a').find('.dropdown-icon').removeClass('rotate');
            var sub = $(this).next('div');
            if (sub) {
                $(sub).toggleClass('active');
            }
            submenuitem.find('div.active').prev('a').find('.dropdown-icon').addClass('rotate');
        });

    }

});

$(document).ready(function(){

    $('.slider').slick({
        slidesToShow: 1,
        dots: true,
        arrows: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000
    });

    $('.content-slider').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1070,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots:true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    });

    $(".fancybox").fancybox();

    if ( $('.multiple-items').length) {
        $('.multiple-items').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: false,
            arrows: false
        });
    }
    if ( $('.single-item').length) {
        $('.single-item').slick({
            dots: false,
            arrows: false
        });
    }
});
